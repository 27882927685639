import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from '../../../utils/AxiosWrapper';
import { wentWrong } from '../../../utils/ToastConfig';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { withStyles } from '@material-ui/core/styles';
import { Delete } from "../../../utils/Delete";
import * as Icons from '@material-ui/icons';
import {
    IconButton, Tooltip,
    Button, DialogActions,
    Dialog, DialogContent, DialogTitle, Grid
} from '@material-ui/core';
import ForBooleanFilter from '../../../utils/ForBooleanFilter';
import { getUser } from '../../../utils/CommonFunctions';
import SearchBar from 'material-ui-search-bar';
import {  PulseLoader } from 'react-spinners';

const CheckboxTable = checkboxHOC(ReactTable);
const commonHeaderStyle = {
    textAlign: 'center',
    textTransform: 'uppercase',
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: '#04764e',
    color: "#ffff",

};

const styles = theme => ({
    searchWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 30px 10px 30px',
    },
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
    center: {
        textAlign: 'center'
    },
    starButton: {
        padding: '3px',
        // color: '#f2ce3e'
    },
    addNewBtn: {
        width: 'fit-content',
        height: 40,
        padding: '0 15px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        backgroundColor: '#5EB3E4',
        '&:hover': {
            backgroundColor: '#4FA2CD',
        },
    },
    paginationButton: {
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#5EB3E4',
        color: 'white',
        border: 'none',
        cursor: 'pointer'
    },
    tableCell:{
        textAlign: 'center',
        paddingLeft: '10px',
        height: '50px',
        alignItems: 'center',
        overflowY: 'hidden',
        overflowX: 'hidden'
    },

    dropDowns: { 
        backgroundColor: '#F4F6F7', 
        color: '#333333', fontSize: '14px', 
        border: 'none', 
        borderBottom: '1px solid #333333', 
        marginRight: '20px', 
        cursor: 'pointer', 
        padding: '8px 12px', 
        borderRadius: '4px', '&:focus': 
        { outline: 'none', 
        boxShadow: '0 0 0 2px #333333', }, 
        '&:hover': { backgroundColor: '#E2E5E7', },
        '& option': 
        { 
            backgroundColor: '#FFFFFF', 
            color: '#333333', 
            fontSize: '14px', 
        } 
    },

    filtersSection: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        paddingLeft: '30px',
        paddingTop: '18px',
    },

    resetButton: {
        background: 'linear-gradient(90deg, #E6E9EC, #D1D6D8)', // Lighter gray gradient
        color: '#333333',
        fontSize: '14px',
        fontWeight: 'normal',
        border: 'none',
        padding: '8px 12px',
        borderRadius: '4px', // Matches dropdown shape
        cursor: 'pointer',
        transition: 'background 0.3s ease', // Smooth hover transition
        borderBottom: '1px solid #333333', // Underline like dropdown
        '&:hover': {
            background: 'linear-gradient(90deg, #E2E5E7, #D5D8DA)', // Matching hover gradient with a darker tone
        },
        '&:active': {
            background: 'linear-gradient(90deg, #D5D8DA, #C7CACD)', // Slightly darker gradient for active state
        },
        '&:focus': {
            outline: 'none',
            boxShadow: '0 0 0 2px #333333', // Optional: subtle focus indicator
        },
    }
    
});
export class ViewCrop extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            selection: [],
            selectAll: false,
            crop: [],
            deleteFavorite: false,
            deleteCropPopUp: false,
            deleteFavoriteId: -1,
            deleteCropName: "",
            user: {},
            searchBarValue: "",
            deleteCropPopUp: false,
            data: [],
            totalPages: 0,
            page: 0,
            pageSize: 10,
            sorted: null, 
            filtered: [],
            load: true,
            allGroups: [],
            selectedGroup: "",
            allSubGroups: [],
            selectedSubGroup: ""

        };
    }

    componentDidMount() {
        this.fetchAllCropGroups();
        this.fetchAllCropSubGroups();
           
        this.setState({
            user: getUser()
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedGroup !== this.state.selectedGroup ||
            prevState.selectedSubGroup !== this.state.selectedSubGroup ||
            prevState.searchBarValue !== this.state.searchBarValue
        ) {
            this.state.load = true;
            this.reactTable.fireFetchData();
        }
    }

    getData(state) {
        let filtered = [...this.state.filtered];

        this.setState({                 
            load: true
        });

        if (this.state.searchBarValue) {
            filtered.push({ id: 'naziv', value: this.state.searchBarValue });
        }
       
        if (this.state.selectedGroup) {
            filtered.push({ id: 'id_kultura_grupa', value: this.state.selectedGroup });
        }

        if (this.state.selectedSubGroup) {
            filtered.push({ id: 'id_kultura_podgrupa', value: this.state.selectedSubGroup });
        }

        this.setState({ data: [] });
        axios.get(`api/crop/read`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: filtered }
        })
            .then(res => {
                if(res.data.data.length> 0){
                    this.setState({
                        id_klijent_vlasnik: res.data.data[0].id_klijent_vlasnik,
                    })
                }
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,                  
                    load: false
                }
            );
            })
            .catch(error => {
                wentWrong(i18n.t('Something went wrong, please try again!'));
                console.log('Error 1 (getData): ', error);
            })
    }

    fetchAllCropGroups() {
        axios.get(`api/crop_group/drop_down`)
        .then(res => {
            const groups = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
            this.setState({ allGroups: groups,
             });
        })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    fetchAllCropSubGroups() {
        axios.get(`api/crop_subgroup/drop_down`)
        .then(res => {
            const sub_groups = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
            this.setState({ allSubGroups: sub_groups,
             });
        })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    deleteCropFavorite(id_kultura) {
        axios.post(`api/crop/deleteFavorite`, { id_kultura })
            .then(res => {
                console.log("Successfully removed from favorites.");
            })
            .catch(() => wentWrong(i18n.t('Something went wrong')));
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            showFilters: nextProps.showFilters,
            crops: nextProps.crops,
            crop: nextProps.crop,
            cropsFetching: nextProps.cropsFetching,
            cropGroupDropdown: immutable.asMutable(nextProps.cropGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            groupFetching: nextProps.groupFetching,
            groupFetchingFailed: nextProps.groupFetchingFailed,
            cropSubgroupDropdown: immutable.asMutable(nextProps.cropSubgroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            subgroupFetching: nextProps.subgroupFetching,
            subgroupFetchingFailed: nextProps.subgroupFetchingFailed
        })
    }

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.crop.filter((crop) => {
                    return crop.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'cropObj');
            }
        });
        this.props.setSelection(selection, 'crop');
    };
    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
        this.props.setSelection(selection, 'crop');
    };

    addCropToFavorite(id_kultura) {
        axios.post(`api/crop/addFavorite`, { id_kultura})
            .then(res => {
                console.log("Successfully added to favorites.");
                this.reactTable.fireFetchData();
            })
            .catch(() => wentWrong(i18n.t('Something went wrong')));
}


    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onDeleteClick = (cropRow) => {
        this.setState({ deleteFavorite: !this.state.deleteFavorite, cropRow });
    }

    deleteItem = () => {
        const { cropRow } = this.state;
        this.deleteCropFavorite(cropRow.original.id);
        const crop = [...this.state.crop];
        let cropObj = Object.assign({}, crop[cropRow.index]);
        cropObj[cropRow.column.id] = !cropRow.value;
        crop[cropRow.index] = cropObj;
        this.setState({ deleteFavorite: false, crop });
        this.reactTable.fireFetchData();
    }

    handleAddFavorite = () => {
        const {row, cena, cropObj } = this.state;
        this.addCropToFavorite((row && row.original.id) || cropObj.id);
        this.setState({ cropPopup: false });
        this.reactTable.fireFetchData();
    }

    handlePopUpFavorite = (row) => {
        if (row.value) {
            this.onDeleteClick(row);
        } else {
            this.setState({
                row
            });
            
            this.setState({ cropPopup: true });
        }
    }

    handleSearchBarChange = (searchValue) => {
        this.setState({
            searchBarValue: searchValue,
        });
    }


    handleEditFavorite = (row) => {
        const { addCropToFavorite } = this.props;
        if (row.value) {
            this.onDeleteClick(row);
        } else {
            addCropToFavorite(row.original.id);
            const crop = [...this.state.crop];
            let cropObj = Object.assign({}, crop[row.index]);
            cropObj[row.column.id] = !row.value;
            crop[row.index] = cropObj;
            this.setState({ crop });
        }
    }

    
    handleChangeGroup = (e) => {
        this.setState({ selectedGroup: e.currentTarget.value });
        this.reactTable.state.page = 0;
    }

    handleChangeSubGroup = (e) => {
        this.setState({ selectedSubGroup: e.currentTarget.value });
        this.reactTable.state.page = 0;
    }

    resetMyFilters = () => {
        this.setState({
            searchBarValue: "",
            selectedGroup: "",
            selectedSubGroup: "",
            selectedOperator: ""
        })
        this.reactTable.state.page = 0;
    }

    render() {
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll, user, cropPopup, cropGroupDropdown, cropSubgroupDropdown, showFilters, deleteFavorite } = this.state;
        const { theme, classes, cropsPages, cropsFetching, getViewCrop } = this.props;
    
        const columns = [
            {
                Header: i18n.t('Active'),
                accessor: "favorit",
                filterable: false,
                width: 80,
                headerStyle: commonHeaderStyle,
                show: user.id_korisnik_grupa === 10,
                Cell: (row) => {
                    return (
                        <div className={classes.center}>
                            <Tooltip title={row.value ? i18n.t('Remove from active') : i18n.t('Add to active')}>
                                <IconButton
                                    className={classes.starButton}
                                    style={{ color: row.value ? '#f2ce3e' : 'gray', marginBottom: '0.4vh', height: '1.2vh' }}
                                    onClick={() => this.handlePopUpFavorite(row)}>
                                    {
                                        row.value ? <Icons.GradeRounded /> :
                                            <Icons.GradeOutlined />
                                    }
                                </IconButton>
                            </Tooltip>
                        </div >
                    );
                }
            },
            {
                Header: i18n.t('Title'),
                accessor: 'naziv',
                headerStyle: commonHeaderStyle
            },
            {
                Header: i18n.t('Group'),
                accessor: 'kultura_grupa_naziv',
                headerStyle: commonHeaderStyle,
                Filter: ({ onChange }) => (
                    <Select
                        isMulti
                        name="group"
                        onChange={onChange}
                        closeMenuOnSelect={false}
                        options={this.state.cropGroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
                )
            },
            {
                Header: i18n.t('Subgroup'),
                accessor: 'kultura_podgrupa_naziv',
                headerStyle: commonHeaderStyle,
                Filter: ({ onChange }) => (
                    <Select
                        isMulti
                        name="subgroup"
                        onChange={onChange}
                        closeMenuOnSelect={false}
                        options={cropSubgroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
                )
            },
            {
                id: 'organska_proizvodnja',
                Header: i18n.t('Permitted in organic production'),
                width: 200,
                headerStyle: commonHeaderStyle,
                accessor: d => d.organska_proizvodnja,
                Cell: ({ value }) => (value ? i18n.t('Yes') : i18n.t('No')),
                Filter: ({ onChange }) => (
                    <ForBooleanFilter onChange={onChange} />
                )
            }
        ];
    
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo) => {
                if (rowInfo) {
                    const selected = isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit",
                            lineHeight: '2.5vh',
                            alignItems: 'flex-end'
                        }
                    };
                }
                return {};
            },
            getTheadFilterProps: () => ({
                style: { backgroundColor: theme.palette.primary.main }
            }),
            getTheadTrProps: () => ({
                style: {
                    backgroundColor: theme.palette.secondary.main,
                    color: '#FFFFFF'
                }
            })
        };
    
        return (
            <div>
                <div className={classes.searchWrap}>
                    <SearchBar
                        className={classes.searchBar}
                        placeholder={i18n.t('Search by name')}
                        cancelOnEscape={true}
                        value={this.state.searchBarValue}
                        onChange={(searchValue) => this.handleSearchBarChange(searchValue)}
                        onCancelSearch={() => this.handleSearchBarChange('')}
                    />
                    
                </div>
                <div className={classes.filtersSection}>
                    <Tooltip title={i18n.t('Group By')}>
                    <select className={classes.dropDowns} value={this.state.selectedGroup} onChange={this.handleChangeGroup} name="group" id="group">
                        <option value="">{i18n.t('Crop group')}</option>
                        {this.state.allGroups && this.state.allGroups.map((status) => (
                            <option key={status.id} value={status.id}>{status.value}</option>
                        ))}
                    </select>
                    </Tooltip>
                    <select className={classes.dropDowns} value={this.state.selectedSubGroup} onChange={this.handleChangeSubGroup} name="sub_group" id="sub_group">
                        <option value="">{i18n.t('Crop subgroup')}</option>
                        {this.state.allSubGroups && this.state.allSubGroups.map((status) => (
                            <option key={status.id} value={status.id}>{status.value}</option>
                        ))}
                    </select>
                    
                    <button className={classes.resetButton} onClick={this.resetMyFilters}>{i18n.t('Reset filters')}</button>
                </div>
                <div style={{ border: '1px solid #cecece', backgroundColor: '#fff', margin: '0 30px 30px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'column' }}>
                        <ReactTable ref={r => (this.reactTable = r)}
                            manual
                            paging={true}
                            data={this.state.data}
                            columns={columns}
                            keyField={'id'}
                            pageRangeDisplayed={false}
                            defaultPageSize={this.state.pageSize}
                            pages={this.state.totalPages}
                            showPaginationBottom={true}
                            onFetchData={(state) => this.getData(state)}
                            firtelable={true}
                            previousText={'<'}
                            nextText={'>'}
                            pageText={''}
                            ofText={i18n.t('of')}
                            showPageSizeOptions={false}
                            showPagination={true}
                            rowsText={''}
                            PreviousComponent={(props) => (
                                <button {...props} 
                                className={
                                    classes.paginationButton}
                                >{props.children}</button>
                            )}
                            NextComponent={(props) => (
                                <button {...props} className={
                                    classes.paginationButton}
                                >{props.children}</button>
                            )}
                            getTrProps={() => {
                                    return {
                                        style: {
                                            textAlign: 'center',
                                            paddingLeft: '10px',
                                            height: '50px',
                                            alignItems: 'center',
                                            overflowY: 'hidden',
                                            overflowX: 'hidden'
                                        }
                                    };
                                
                            }}
                            LoadingComponent={() => (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <PulseLoader loading={this.state.load}/>
                                </div>                         
                            )}
                            onPageChange={() => {
                                this.state.load= true ;
                              }}
                              NoDataComponent={() => (
                                <div style={{ textAlign: 'center', padding: '20px', display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: -300 }}>
                                    {!this.state.load && <span>{i18n.t('No rows found')}</span>}
                                </div>
                            )}
                    />
                </div>
                {this.state.deleteFavorite &&
                    <Delete
                        delete={this.state.deleteFavorite}
                        handleClose={this.onDeleteClick}
                        deleteItem={this.deleteItem}
                        favorite={true} />
                }
                {(cropPopup || this.props.favorit) ? (
                        <Dialog
                            open={cropPopup || this.props.favorit}
                            onClose={this.closePopup}
                            disableBackdropClick
                            maxWidth="sm"
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">{i18n.t('Add to active')}</DialogTitle>
                            <DialogContent>
                                {i18n.t('Do you want to add the item to active?')}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={this.handleAddFavorite}
                                    variant="contained"
                                    color="primary"
                                >
                                    {i18n.t('Ok')}
                                </Button>
                                <Button
                                    onClick={this.closeFavorite}
                                    variant="contained"
                                    color="primary"
                                >
                                    {i18n.t('Cancel')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : (
                        <Dialog
                            open={cropPopup || this.props.favorit}
                            onClose={this.closePopup}
                            disableBackdropClick
                            maxWidth="sm"
                            aria-labelledby="responsive-dialog-title"
                        >
                            
                            <DialogActions>
                                <Button
                                    onClick={this.handleAddFavorite}
                                    variant="contained"
                                    color="primary"
                                >
                                    {i18n.t('Ok')}
                                </Button>
                                <Button
                                    onClick={this.closeFavorite}
                                    variant="contained"
                                    color="primary"
                                >
                                    {i18n.t('Cancel')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}                
               </div>
        )
        
    }
    
    render2() {
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll, user } = this.state;
        const { theme, classes } = this.props;

        const columns = [
            {
                Header: i18n.t('Active'),
                accessor: "favorit",
                filterable: false,
                width: 60,
                show: user.id_korisnik_grupa === 10 ? true : false,
                Cell: (row) => {
                    return (
                        <div className={classes.center}>
                            <Tooltip title={row.value ? i18n.t('Remove from active') : i18n.t('Add to active')}>
                                <IconButton
                                    className={classes.starButton}
                                    style={{ color: row.value ? '#f2ce3e' : 'gray', marginBottom: '0.4vh', height: '1.2vh' }}
                                    onClick={() => this.handleEditFavorite(row)}>
                                    {
                                        row.value ? <Icons.GradeRounded /> :
                                            <Icons.GradeOutlined />
                                    }
                                </IconButton>
                            </Tooltip>
                        </div >
                    );
                }
            },
            {
                Header: i18n.t('Title'),
                accessor: 'naziv'
            },
            {
                Header: i18n.t('Group'),
                accessor: 'kultura_grupa_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.cropGroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Subgroup'),
                accessor: 'kultura_podgrupa_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.cropSubgroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                id: 'organska_proizvodnja',
                Header: i18n.t('Permitted in organic production'),
                accessor: d => d.organska_proizvodnja,
                width: 240,
                Cell: ({ value }) => value === true ? i18n.t('Yes') : i18n.t('No'),
                Filter: ({ filter, onChange }) =>
                    <ForBooleanFilter
                        onChange={(s) => {
                            onChange(s);
                        }}
                    />

            },
            // {
            //     Header: i18n.t('Average yield'),
            //     accessor: 'prosecan_prinos',
            //     filterable: false
            // },
            // {
            //     Header: i18n.t('Precipitation of'),
            //     accessor: 'padavine_od',
            //     filterable: false
            // },
            // {
            //     Header: i18n.t('Precipitation up to'),
            //     accessor: 'padavine_do',
            //     filterable: false
            // },
            // {
            //     Header: i18n.t('Sum of mean daily temperatures of'),
            //     accessor: 'stepeni_od',
            //     filterable: false
            // },
            // {
            //     Header: i18n.t('Sum of average daily temperatures up to'),
            //     accessor: 'stepeni_do',
            //     filterable: false,
            // }
        ];
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit",
                            lineHeight: '2.5vh',
                            alignItems: 'flex-end'
                        }
                    };
                }
                return {};
            },
            getTheadFilterProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.primary.main
                    }
                };
            },
            getTheadTrProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.secondary.main,
                        color: '#FFFFFF'
                    }
                };
            },
        };

        return (
            <div className="ReactTable">
                <CheckboxTable
                    ref={r => (this.checkboxTable = r)}
                    data={this.state.crop}
                    columns={columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    showPaginationTop
                    keyField={'id'}
                    showPaginationBottom={false}
                    pages={this.props.cropsPages}
                    loading={this.props.cropsFetching}
                    manual
                    onFetchData={(state, instance) => this.props.getViewCrop(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
            {this.state.deleteMaterialPopUp && <Delete delete={this.state.deleteMaterialPopUp} handleClose={this.closeDeleteMaterial} deleteItem={this.handleDeleteMaterial} />}  
            </div >
        )
    }
};



export default withStyles(styles, { withTheme: true })(connect(
   
)(ViewCrop));