import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from '../../../utils/AxiosWrapper';
import { wentWrong } from '../../../utils/ToastConfig';
import { getGroup, getSubgroup, getViewImplements, setRowInReducer, setInitialState, addImplementFavorite } from '../../../actions/resources/ImplementsActions';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { withStyles } from '@material-ui/core/styles';
import {
    IconButton, Tooltip,
    Button, DialogActions,
    Dialog, DialogContent, DialogTitle, Grid
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Delete } from "../../../utils/Delete";
import { getUser } from '../../../utils/CommonFunctions';
import SearchBar from 'material-ui-search-bar';
import AddIcon from '@material-ui/icons/Add';
import {  PulseLoader } from 'react-spinners';

const CheckboxTable = checkboxHOC(ReactTable);

const commonHeaderStyle = {
    textAlign: 'center',
    textTransform: 'uppercase',
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: '#04764e',
    color: "#ffff",

};

const styles = theme => ({
    searchWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 30px 10px 30px',
    },
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
    center: {
        textAlign: 'center'
    },
    starButton: {
        padding: '3px',
        // color: '#f2ce3e'
    },
    addNewBtn: {
        width: 'fit-content',
        height: 40,
        padding: '0 15px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        backgroundColor: '#5EB3E4',
        '&:hover': {
            backgroundColor: '#4FA2CD',
        },
    },
    paginationButton: {
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#5EB3E4',
        color: 'white',
        border: 'none',
        cursor: 'pointer'
    },
    tableCell:{
        textAlign: 'center',
        paddingLeft: '10px',
        height: '50px',
        alignItems: 'center',
        overflowY: 'hidden',
        overflowX: 'hidden'
    },

    dropDowns: { 
        backgroundColor: '#F4F6F7', 
        color: '#333333', fontSize: '14px', 
        border: 'none', 
        borderBottom: '1px solid #333333', 
        marginRight: '20px', 
        cursor: 'pointer', 
        padding: '8px 12px', 
        borderRadius: '4px', '&:focus': 
        { outline: 'none', 
        boxShadow: '0 0 0 2px #333333', }, 
        '&:hover': { backgroundColor: '#E2E5E7', },
        '& option': 
        { 
            backgroundColor: '#FFFFFF', 
            color: '#333333', 
            fontSize: '14px', 
        } 
    },

    filtersSection: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        paddingLeft: '30px',
        paddingTop: '18px',
    },

    resetButton: {
        background: 'linear-gradient(90deg, #E6E9EC, #D1D6D8)', // Lighter gray gradient
        color: '#333333',
        fontSize: '14px',
        fontWeight: 'normal',
        border: 'none',
        padding: '8px 12px',
        borderRadius: '4px', // Matches dropdown shape
        cursor: 'pointer',
        transition: 'background 0.3s ease', // Smooth hover transition
        borderBottom: '1px solid #333333', // Underline like dropdown
        '&:hover': {
            background: 'linear-gradient(90deg, #E2E5E7, #D5D8DA)', // Matching hover gradient with a darker tone
        },
        '&:active': {
            background: 'linear-gradient(90deg, #D5D8DA, #C7CACD)', // Slightly darker gradient for active state
        },
        '&:focus': {
            outline: 'none',
            boxShadow: '0 0 0 2px #333333', // Optional: subtle focus indicator
        },
    }
    
});
export class ViewImplement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: [],
            selectAll: false,
            implement: [],
            deleteFavorite: false,
            deleteImplementPopUp: false,
            user: {},
            searchBarValue: "",
            data: [],
            totalPages: 0,
            page: 0,
            pageSize: 10,
            sorted: null, 
            filtered: [],
            load: true,
            allGroups: [],
            selectedGroup: "",
            allSubGroups: [],
            selectedSubGroup: ""
            /*
            add: this.props.add,
            edit: this.props.edit,
            showFilters: this.props.showFilters
            */

        };
    }

    componentDidMount() {
            this.fetchAllImplementGroups();
            this.fetchAllImplementSubGroups();
        
        this.setState({
            user: getUser()
        })
    }


   

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedGroup !== this.state.selectedGroup ||
            prevState.selectedSubGroup !== this.state.selectedSubGroup ||
            prevState.searchBarValue !== this.state.searchBarValue
        ) {
            this.state.load = true;
            this.reactTable.fireFetchData();
        }
    }

    getData(state) {
        let filtered = [...this.state.filtered];

        this.setState({                 
            load: true
        });

        if (this.state.searchBarValue) {
            filtered.push({ id: 'naziv', value: this.state.searchBarValue });
        }
       
        if (this.state.selectedGroup) {
            filtered.push({ id: 'id_prikljucna_masina_grupa', value: this.state.selectedGroup });
        }

        if (this.state.selectedSubGroup) {
            filtered.push({ id: 'id_prikljucna_masina_podgrupa', value: this.state.selectedSubGroup });
        }

        this.setState({ data: [] });
        axios.get(`api/implement/read`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: filtered }
        })
            .then(res => {
                if(res.data.data.length> 0){
                    this.setState({
                        id_klijent_vlasnik: res.data.data[0].id_klijent_vlasnik,
                    })
                }
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,                  
                    load: false
                }
            );
            })
            .catch(error => {
                wentWrong(i18n.t('Something went wrong, please try again!'));
                console.log('Error 1 (getData): ', error);
            })
    }

    /*
    componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getViewImplements(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            this.props.setInitialState('deleted');

        }
        if (nextProps.implementPostingSuccess === true) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
            this.props.handleClose();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getViewImplements(wrapped.state);
            this.props.setInitialState('implementPostingSuccess');
        }
        this.setState({
            add: nextProps.add,
            showFilters: nextProps.showFilters,
            implement: nextProps.implement,
            implementFetching: nextProps.implementFetching,
            implementGroupDropdown: immutable.asMutable(nextProps.implementGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            groupFetching: nextProps.groupFetching,
            groupFetchingFailed: nextProps.groupFetchingFailed,
            implementSubgroupDropdown: immutable.asMutable(nextProps.implementSubgroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            subgroupFetching: nextProps.subgroupFetching,
            subgroupFetchingFailed: nextProps.subgroupFetchingFailed,
        })
        if (nextProps.add == true && nextProps.edit == false) {
            this.props.setInitialState('implementObj');
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
        }

    }*/

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.implement.filter((imp) => {
                    return imp.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'implementObj');
            }
        });
        this.props.setSelection(selection, 'implement');
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;

            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
        this.props.setSelection(selection, 'implement');

    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onDeleteClick = (ImplementRow) => {
        this.setState({ deleteFavorite: !this.state.deleteFavorite, ImplementRow });
    }

    deleteItem = () => {
        const { ImplementRow } = this.state;
        this.deleteImplementFavorite(ImplementRow.original.id);
        const implement = [...this.state.implement];
        let implementObj = Object.assign({}, implement[ImplementRow.index]);
        implementObj[ImplementRow.column.id] = !ImplementRow.value;
        implement[ImplementRow.index] = implementObj;
        this.setState({ deleteFavorite: false, implement });
    }

    closeFavorite = () => {
        this.setState({ implementPopup: false });
    }


    fetchAllImplementGroups() {
        axios.get(`api/implement_group/drop_down`)
        .then(res => {
            const groups = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
            this.setState({ allGroups: groups,
             });
        })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    fetchAllImplementSubGroups() {
        axios.get(`api/implement_subgroup/drop_down`)
        .then(res => {
            const sub_groups = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
            this.setState({ allSubGroups: sub_groups,
             });
        })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    addImplementToFavorite(id_prikljucna_masina) {
        axios.post(`api/implement/addFavorite`, { id_prikljucna_masina })
            .then(res => {
                console.log("Successfully added to favorites.");
                this.reactTable.fireFetchData();
            })
            .catch(() => wentWrong(i18n.t('Something went wrong')));
    }

    deleteImplementFavorite(id_prikljucna_masina) {
        axios.post(`api/implement/deleteFavorite`, { id_prikljucna_masina })
            .then(res => {
                console.log("Successfully removed from favorites.");
            })
            .catch(() => wentWrong(i18n.t('Something went wrong')));
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onDeleteClick = (implementRow) => {
        this.setState({ deleteFavorite: !this.state.deleteFavorite, implementRow });
    }

    
    deleteItem = () => {
        const { implementRow } = this.state;
        this.deleteImplementFavorite(implementRow.original.id);
        const implement = [...this.state.implement];
        let implementObj = Object.assign({}, implement[implementRow.index]);
        implementObj[implementRow.column.id] = !implementRow.value;
        implement[implementRow.index] = implementObj;
        this.setState({ deleteFavorite: false, implement });
        this.reactTable.fireFetchData();
    }


    handlePopUpFavorite = (row) => {
        if (row.value) {
            this.onDeleteClick(row);
        } else {
            this.setState({
                row
            });
            
            this.setState({ implementPopup: true });
        }
    }


    handleAddFavorite = () => {
        const {row, implementObj } = this.state;
        this.addImplementToFavorite((row && row.original.id) || implementObj.id);
        this.setState({ implementPopup: false});
        this.reactTable.fireFetchData();
    }


    handleSearchBarChange = (searchValue) => {
        this.setState({
            searchBarValue: searchValue,
        });
    }

    handleDeleteButtonClick = (id, name) => {
        if(!this.state.deleteImplementPopUp){
            this.setState({
                deleteImplementId: id,
                deleteImplementName: name,
            })
        }  
        this.setState({
            deleteImplementPopUp: !this.state.deleteImplentPopUp,
        })
    }

    handleChangeGroup = (e) => {
        this.setState({ selectedGroup: e.currentTarget.value });
        this.reactTable.state.page = 0;
    }

    handleChangeSubGroup = (e) => {
        this.setState({ selectedSubGroup: e.currentTarget.value });
        this.reactTable.state.page = 0;
    }

    resetMyFilters = () => {
        this.setState({
            searchBarValue: "",
            selectedGroup: "",
            selectedSubGroup: "",
            selectedOperator: ""
        })
        this.reactTable.state.page = 0;
    }

    render() {
        
        const { selectAll, user, implementPopup } = this.state;
        const { theme, classes } = this.props;

        const columns = [
            {
                Header: i18n.t('Active'),
                accessor: "favorit",
                filterable: false,
                width: 80,
                headerStyle: commonHeaderStyle,
                show: user.id_korisnik_grupa === 10 ? true : false,
                Cell: (row) => {
                    return (
                        <div className={classes.center}>
                            <Tooltip title={row.value ? i18n.t('Remove from active') : i18n.t('Add to active')}>
                                <IconButton
                                    className={classes.starButton}
                                    style={{ color: row.value ? '#f2ce3e' : 'gray', marginBottom: '0.4vh', height: '1.2vh' }}
                                    onClick={() => this.handlePopUpFavorite(row)}>
                                    {
                                        row.value ? <Icons.GradeRounded /> :
                                            <Icons.GradeOutlined />
                                    }
                                </IconButton>
                            </Tooltip>
                        </div >
                    );
                }
            },
            {
                Header: i18n.t('Title'),
                accessor: 'naziv',
                headerStyle: commonHeaderStyle
            },
            {
                Header: i18n.t('Implement group name'),
                accessor: 'prikljucna_masina_grupa_naziv',
                headerStyle: commonHeaderStyle,
                Filter: ({ onChange }) => (
                    <Select
                        isMulti
                        name="group"
                        onChange={onChange}
                        closeMenuOnSelect={false}
                        options={this.state.implementGroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
                )
            },
            {
                Header: i18n.t('Implement subgroup name'),
                accessor: 'prikljucna_masina_podgrupa_naziv',
                headerStyle: commonHeaderStyle,
                Filter: ({ onChange }) => (
                    <Select
                        isMulti
                        name="subgroup"
                        onChange={onChange}
                        closeMenuOnSelect={false}
                        options={this.state.implementSubgroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
                )
            }
        ];
        
           
        return (
            <div>
                <div className={classes.searchWrap}>
                    <SearchBar
                        className={classes.searchBar}
                        placeholder={i18n.t('Search by name')}
                        cancelOnEscape={true}
                        value={this.state.searchBarValue}
                        onChange={(searchValue) => this.handleSearchBarChange(searchValue)}
                        onCancelSearch={() => this.handleSearchBarChange('')}
                    />
                </div>
                <div className={classes.filtersSection}>
                    <select className={classes.dropDowns} value={this.state.selectedGroup} onChange={this.handleChangeGroup} name="group" id="group">
                        <option value="">{i18n.t('Implement group')}</option>
                        {this.state.allGroups && this.state.allGroups.map((status) => (
                            <option key={status.id} value={status.id}>{status.value}</option>
                        ))}
                    </select>
                    <select className={classes.dropDowns} value={this.state.selectedSubGroup} onChange={this.handleChangeSubGroup} name="sub_group" id="sub_group">
                        <option value="">{i18n.t('Subgroup')}</option>
                        {this.state.allSubGroups && this.state.allSubGroups.map((status) => (
                            <option key={status.id} value={status.id}>{status.value}</option>
                        ))}
                    </select>
                    <button className={classes.resetButton} onClick={this.resetMyFilters}>{i18n.t('Reset filters')}</button>
                </div>
                <div style={{ border: '1px solid #cecece', backgroundColor: '#fff', margin: '0 30px 30px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'column' }}>
                        <ReactTable ref={r => (this.reactTable = r)}
                            manual
                            paging={true}
                            data={this.state.data}
                            columns={columns}
                            keyField={'id'}
                            pageRangeDisplayed={false}
                            defaultPageSize={this.state.pageSize}
                            pages={this.state.totalPages}
                            showPaginationBottom={true}
                            onFetchData={(state) => this.getData(state)}
                            firtelable={true}
                            previousText={'<'}
                            nextText={'>'}
                            pageText={''}
                            ofText={i18n.t('of')}
                            showPageSizeOptions={false}
                            showPagination={true}
                            rowsText={''}
                            PreviousComponent={(props) => (
                                <button {...props} 
                                className={
                                    classes.paginationButton}
                                >{props.children}</button>
                            )}
                            NextComponent={(props) => (
                                <button {...props} className={
                                    classes.paginationButton}
                                >{props.children}</button>
                            )}
                            getTrProps={() => {
                                    return {
                                        style: {
                                            textAlign: 'center',
                                            paddingLeft: '10px',
                                            height: '50px',
                                            alignItems: 'center',
                                            overflowY: 'hidden',
                                            overflowX: 'hidden'
                                        }
                                    };
                                
                            }}
                            LoadingComponent={() => (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <PulseLoader loading={this.state.load}/>
                                </div>                         
                            )}
                            onPageChange={() => {
                                this.state.load= true ;
                              }}
                              NoDataComponent={() => (
                                <div style={{ textAlign: 'center', padding: '20px', display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: -300 }}>
                                    {!this.state.load && <span>{i18n.t('No rows found')}</span>}
                                </div>
                            )}
                    />
                </div>
                {this.state.deleteFavorite &&
                    <Delete
                        delete={this.state.deleteFavorite}
                        handleClose={this.onDeleteClick}
                        deleteItem={this.deleteItem}
                        favorite={true} />
                }
                {(implementPopup || this.props.favorit) ? (
                        <Dialog
                            open={implementPopup || this.props.favorit}
                            onClose={this.closePopup}
                            disableBackdropClick
                            maxWidth="sm"
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">{i18n.t('Add to active')}</DialogTitle>
                            <DialogContent>
                                {i18n.t('Do you want to add the item to active?')}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={this.handleAddFavorite}
                                    variant="contained"
                                    color="primary"
                                >
                                    {i18n.t('Ok')}
                                </Button>
                                <Button
                                    onClick={this.closeFavorite}
                                    variant="contained"
                                    color="primary"
                                >
                                    {i18n.t('Cancel')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : (
                        <Dialog
                            open={implementPopup || this.props.favorit}
                            onClose={this.closePopup}
                            disableBackdropClick
                            maxWidth="sm"
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogActions>
                                <Button
                                    onClick={this.handleAddFavorite}
                                    variant="contained"
                                    color="primary"
                                >
                                    {i18n.t('Ok')}
                                </Button>
                                <Button
                                    onClick={this.closeFavorite}
                                    variant="contained"
                                    color="primary"
                                >
                                    {i18n.t('Cancel')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}                

                {this.state.deleteMaterialPopUp && <Delete delete={this.state.deleteImplementPopUp} handleClose={this.closeDeleteImplement} deleteItem={this.handleDeleteImplement} />}
               </div>
        )
    }

};



/*

function mapStateToProps(state) {
    return {
        implement: state.implementReducer.implement,
        implementFetching: state.implementReducer.implementFetching,
        implementPages: state.implementReducer.implementPages,
        groupFetching: state.implementReducer.groupFetching,
        groupFetchingFailed: state.implementReducer.groupFetchingFailed,
        implementGroupDropdown: state.implementReducer.implementGroupDropdown,
        implementSubgroupDropdown: state.implementReducer.implementSubgroupDropdown,
        subgroupFetching: state.implementReducer.subgroupFetching,
        subgroupFetchingFailed: state.implementReducer.subgroupFetchingFailed,
        deleted: state.implementReducer.deleted,
        implementPostingSuccess: state.implementReducer.implementPostingSuccess
    }
}


function mapDispatchToProps(dispatch) {
    return {
        getGroup: () => dispatch(getGroup()),
        getSubgroup: () => dispatch(getSubgroup()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getViewImplements: (state, instance) => dispatch(getViewImplements(state, instance)),
        addImplementFavorite: (id_prikljucna_masina) => dispatch(addImplementFavorite(id_prikljucna_masina)),
        deleteImplementFavorite: (id_prikljucna_masina) => dispatch(deleteImplementFavorite(id_prikljucna_masina)),
    }
}
*/

export default withStyles(styles, { withTheme: true })(connect(

)(ViewImplement));