import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import * as Icons from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { toast } from 'react-toastify';
import ViewCrop from './ViewCrop';
import { deleteCrop, setInitialState } from '../../../actions/resources/CropActions';
import { Delete } from "../../../utils/Delete";


export class CropMainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            selectedIndex: 0,
            selection: [],
            name: '',
            delete: false,
            edit: false,
            add: false
        };
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleSelection = (selection, name) => {
        this.setState({
            selection: selection,
            name: name
        })
    }

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deleteCrop(this.state.selection, this.state.name);
        this.setState({ delete: false })
    }
    onEditClick() {
        this.setState({ add: !this.state.add, edit: true });
    }


    render() {
        const { selection, favorit } = this.state;
        let content;
        content =
            <div className="table-header">
                {/* <Button
                    onClick={() => this.onSearchClick()}
                    variant="fab"
                    color="secondary"
                    aria-label="add"
                    className="action-button"
                >
                    <span className="icon-search headerIcon" />
                </Button> */}
                {selection.length == 1 &&
                    <Button
                        onClick={() => this.onEditFavorit()}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-Izmeni1 headerIcon" />
                    </Button>
                }
            </div>
        return (
            <div className="page">
                {content}
                {this.state.selectedIndex === 0 && <ViewCrop favorit={favorit} onEditFavorit={this.onEditFavorit} setSelection={this.handleSelection} edit={this.state.edit} add={this.state.add} showFilters={this.state.showFilters}
                    handleClose={this.handleClick} />}
            </div>
        )
    }

};
    


function mapStateToProps(state) {
    return {
        deleted: state.cropReducer.deleted,
        deleteCropFailed: state.cropReducer.deleteCropFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteCrop: (selection, name) => dispatch(deleteCrop(selection, name)),
        setInitialState: (component) => dispatch(setInitialState(component)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CropMainComponent);