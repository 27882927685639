import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../../i18n/i18n';

import { Fab, Button, Menu, IconButton } from '@material-ui/core';
import axios from '../../../utils/AxiosWrapper';

import * as Icons from '@material-ui/icons';

import * as style from './ExtraExpenses.module.css';
import SearchBar from 'material-ui-search-bar';
import AddIcon from '@material-ui/icons/Add';
import { wentWrong } from '../../../utils/ToastConfig';
import { Tooltip } from '@material-ui/core';
import CalendarMonthIcon from '@material-ui/icons/DateRangeSharp';
import AddNewExpense from './AddNewExpense';
import ReactPaginate from 'react-paginate';
import { Delete } from '../../../utils/Delete';
import Export from '../../../utils/Export';

var FileSaver = require('file-saver');
var XLSX = require('xlsx');



export default class ExtraExpenses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            allExpenses: [],
            allMonths: [],
            filteredExpenses: [],

            naziv: '',
            mesec: '',
            godina: '',
            ogranak: '',
            searchBarValue: '',

            isFirstRender: true,

            currentPage: 0,
            itemsPerPage: 6,

            nazivi: [],
            meseci: [],
            godine: [],
            ogranci: [],

            addNew: false,
            preview: false,
            previewData: null,
            delete: false,
            deleteId: null,
            editMode: false,
            itemForEditing: null,

            numberOfAllExtraExpenses: 0,
            numberOfFilteredExtraExpenses: 0,
            totalCostAllExtraExpenses: 0,
            totalCostFilteredExtraExpenses: 0,

            exportWindow: false,
            docTitle: null,

        };
    }

    componentDidMount() {
        this.fetchAll();

        this.setupPageCount();

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.naziv !== this.state.naziv ||
            prevState.mesec !== this.state.mesec ||
            prevState.ogranak !== this.state.ogranak ||
            prevState.searchBarValue !== this.state.searchBarValue) {
            if (!this.state.isFirstRender) this.setState({ currentPage: 0 });

            this.updateFilteredExpenses();
            this.saveFiltersInSessionStorage();
        }

        if (prevState.filteredExpenses !== this.state.filteredExpenses) {
            this.setupPageCount();
        }
    }


    fetchAll = () => {
        axios.get(`api/extra_expenses/read`)
            .then(res => {
                console.log("111", res)
                this.setState({ allExpenses: res.data.data }, () => {
                    console.log("this.state.allExpenses : ", this.state.allExpenses)
                    this.setState({ numberOfAllExtraExpenses: this.state.allExpenses.length });
                    this.calculateTotalCostExtraExpenses();
                    this.calculateCostFilteredExtraExpenses();
                    this.populateDropdowns();
                    this.updateFilteredExpenses();
                    this.updateFiltersFromSessionStorage();
                })

            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    populateDropdowns = () => {
        // console.log("POPULATE : ", this.state.allExpenses);
        let nazivi = new Set(this.state.allExpenses && this.state.allExpenses.map(item => item.naziv));
        let meseci = new Set(this.state.allExpenses && this.state.allExpenses.map(item => i18n.t(`${item.mesec_naziv}`)));
        let godine = new Set(this.state.allExpenses && this.state.allExpenses.map(item => item.godina_naziv));
        let ogranci = new Set(this.state.allExpenses && this.state.allExpenses.map(item => item.sektor_naziv));


        // let operators = new Set(this.state.allActivities && this.state.allActivities.map(item => item.rukovodilac_ime_prezime));
        // let sectors = new Set(this.state.allActivities && this.state.allActivities.filter(item => item.sektor_naziv !== null).map(item => item.sektor_naziv));


        this.setState({
            nazivi: [...nazivi],
            meseci: [...meseci],
            godine: [...godine],
            ogranci: [...ogranci],
            filteredExpenses: this.state.allExpenses,
        }, () => {
            this.updateFiltersFromSessionStorage();
            console.log("this.state : ", this.state);
        })
    }

    updateFilteredExpenses = () => {
        this.setState({
            filteredExpenses: this.state.allExpenses
                .filter(item => this.state.naziv === "" ? item : item.naziv === this.state.naziv)
                .filter(item => this.state.mesec === "" ? item : i18n.t(`${item.mesec_naziv}`) === this.state.mesec)
                .filter(item => this.state.godina === "" ? item : item.godina_naziv === this.state.godina)
                .filter(item => this.state.ogranak === "" ? item : item.sektor_naziv === this.state.ogranak)
                .filter(item => this.state.searchBarValue === "" ? item : item.naziv.toLowerCase().includes(this.state.searchBarValue.toLowerCase()))
        }, () => {
            this.setupPageCount();

            this.setState({ numberOfFilteredExtraExpenses: this.state.filteredExpenses.length });

            this.calculateCostFilteredExtraExpenses();
        });
    }

    handleChangeNaziv = (e) => {
        this.setState({ naziv: e.target.value });
    }

    handleChangeMesec = (e) => {
        this.setState({ mesec: e.target.value });
    }

    handleChangeGodina = (e) => {
        this.setState({ godina: e.target.value });
    }

    handleChangeOgranak = (e) => {
        this.setState({ ogranak: e.target.value });
    }

    handleSearchBar = (searchVal) => {
        this.setState({
            searchBarValue: searchVal,
        });
    }

    resetMyFilters = () => {
        this.setState(
            {
                naziv: "",
                mesec: "",
                godina: "",
                ogranak: "",
                searchBarValue: "",
                currentPage: 0,
            })
    }

    handleAddNewExpense = () => {
        this.setState({
            addNew: !this.state.addNew
        });
    }

    handlePageChange = (pageNum) => {
        this.setState({
            currentPage: pageNum.selected,
        })

        sessionStorage.setItem('ExtraExpensesPageNumber', pageNum.selected);
    }

    setupPageCount = () => {
        const pageCount = Math.ceil(this.state.filteredExpenses.length / this.state.itemsPerPage);
        this.setState({ pageCount: pageCount }, () => {
            if (this.state.currentPage >= this.state.pageCount && this.state.pageCount > 0) {
                this.setState({ currentPage: this.state.pageCount - 1 });
            }
        });
    }

    saveFiltersInSessionStorage = () => {
        const extraExpensesFilterValues = JSON.stringify({
            naziv: this.state.naziv,
            mesec: this.state.mesec,
            godina: this.state.godina,
            ogranak: this.state.ogranak,
            searchBarValue: this.state.searchBarValue

        })

        sessionStorage.setItem('extraExpensesFilterValues', extraExpensesFilterValues);

    }

    updateFiltersFromSessionStorage = () => {
        const storedVlues = sessionStorage.getItem('extraExpensesFilterValues');

        if (storedVlues) {
            const parsedFilterValues = JSON.parse(storedVlues);

            this.setState({
                searchBarValue: parsedFilterValues.searchBarValue,
                naziv: parsedFilterValues.naziv,
                mesec: parsedFilterValues.mesec,
                godina: parsedFilterValues.godina,
                ogranak: parsedFilterValues.ogranak,
            }, () => {
                this.updatePageNumberFromSessionStorage();
                this.setupPageCount();
            });
        }


    }

    updatePageNumberFromSessionStorage = () => {
        if (this.state.isFirstRender) {
            const storedNumber = sessionStorage.getItem('ExtraExpensesPageNumber');

            if (storedNumber) {
                this.setState({ currentPage: Number(storedNumber) });
            } else {
                this.setState({ currentPage: 0 });
            }
        }

        this.setState({ isFirstRender: false });
    }

    handlePreviewClick = (obj) => {
        console.log("ASDASDAS :", obj)
        this.setState({ previewData: obj }, () => {
            this.setState({ preview: true })
        })
    }

    handleClosePreview = () => {
        this.setState({ preview: false })
    }

    onDeleteClick = () => {
        this.setState({ delete: false });
    }

    onDeleteExpense = (id) => {
        this.setState({ deleteId: id }, () => {
            this.setState({ delete: true })
        })
    }

    deleteItem = () => {
        axios.post(`api/extra_expenses/delete`, { id: this.state.deleteId })
            .then(res => {
                console.log("IZ DELETE: ", res)
                this.setState({ delete: false });
                this.fetchAll();
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
                this.setState({ delete: false });
            })
    }

    openEditExpense = (item) => {
        this.setState({ itemForEditing: item }, () => this.setState({ editMode: true }))
    }

    handleCloseEdit = () => {
        this.setState({ editMode: false })
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        return userObject;
    }

    calculateTotalCostExtraExpenses() {
        let totalCost = 0
        this.state.allExpenses.forEach(element => { totalCost += parseFloat(element.cena) });
        this.setState({ totalCostAllExtraExpenses: totalCost })
    }

    calculateCostFilteredExtraExpenses() {
        let totalCost = 0
        this.state.filteredExpenses.forEach(element => { totalCost += parseFloat(element.cena) });
        this.setState({ totalCostFilteredExtraExpenses: totalCost })
    }

    handleCloseExportWindow = () => {
        this.setState({ exportWindow: false })
    }

    async exportDocument(title, data) {

        let newData = data.map((item, index) => {
            return {
                Naziv: item.naziv,
                Cena: item.cena,
                Mesec: item.mesec_naziv,
                Godina: item.godina_naziv,
                Ogranak: item.sektor_naziv,
                Ukupna_cena: index === 0 ? this.state.totalCostFilteredExtraExpenses : "",

            }
        });

        try {
            function s2ab(s) {
                const buf = new ArrayBuffer(s.length);
                const view = new Uint8Array(buf);
                for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            const ws = XLSX.utils.json_to_sheet(newData);

            ws['!cols'] = [
                { wpx: 100 },
                { wpx: 200 },
                { wpx: 100 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 100 },
                { wpx: 150 },
                { wpx: 80 },
                { wpx: 180 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 120 }
            ]
            ws['!rows'] = [{ hpx: 30 }];

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
            FileSaver.saveAs(blob, `${title || 'file'}.xlsx`);

        } catch (err) {
            wentWrong(i18n.t('Something went wrong'))
            console.log("BLOB ERROR : ", err)
        }

    };

    exportFunction = () => {
        this.exportDocument(this.state.docTitle, this.state.filteredExpenses);
        this.setState({ exportWindow: false });
    }

    render() {
        let user = this.getUser();

        return (
            <div className="page">

                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                    <div className={style.searchWrap}>
                        <SearchBar
                            className={style.searchBar}
                            placeholder={"Pretraga po radniku"}
                            cancelOnEscape={true}
                            value={this.state.searchBarValue}
                            // onChange={(searchValue) => !searchValue && this.handleSearchBar('')}
                            // onRequestSearch={(searchValue) => this.handleSearchBar(searchValue)}
                            // onCancelSearch={() => this.handleSearchBar('')}
                            onChange={(searchValue) => this.handleSearchBar(searchValue)}
                            onCancelSearch={() => this.handleSearchBar('')}
                        />


                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: '30px' }}>
                                <div style={{ backgroundColor: 'white', padding: '0 10px 0 10px', display: 'flex', minWidth: '300px', justifyContent: 'space-between', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                    <div
                                    // style={{ fontSize: '14px' }}
                                    >{i18n.t('Total number of extra expenses')}:</div>
                                    <div>{this.state.numberOfAllExtraExpenses}</div>
                                </div>
                                <div style={{ backgroundColor: 'white', padding: '0 10px 0 10px', display: 'flex', minWidth: '300px', justifyContent: 'space-between', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                    <div
                                    // style={{ fontSize: '14px' }}
                                    >{i18n.t('Total number of filtered extra expenses')}:</div>
                                    <div>{this.state.numberOfFilteredExtraExpenses}</div>
                                </div>
                            </div>

                            <div>
                                <div style={{ backgroundColor: 'white', padding: '0 10px 0 10px', display: 'flex', minWidth: '400px', justifyContent: 'space-between', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                    <div
                                    // style={{ fontSize: '14px' }}
                                    >{i18n.t('Total cost of extra expenses')}:</div>
                                    <div>{this.state.totalCostAllExtraExpenses.toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ({user.valuta_naziv})</div>
                                </div>
                                <div style={{ backgroundColor: 'white', padding: '0 10px 0 10px', display: 'flex', minWidth: '400px', justifyContent: 'space-between', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                    <div
                                    // style={{ fontSize: '14px' }}
                                    >{i18n.t('Total cost of filtered extra expenses')}:</div>
                                    <div>{this.state.totalCostFilteredExtraExpenses.toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ({user.valuta_naziv})</div>
                                </div>
                            </div>

                        </div>


                        <Button
                            variant="fab"
                            color="primary"
                            aria-label="Add"
                            className={style.addNewBtn}
                            onClick={() => { this.handleAddNewExpense() }}
                        >
                            <AddIcon />
                            {i18n.t('Add new expense')}
                        </Button>

                    </div>

                    <div className={style.filtersSection}>

                        <select className={style.dropDowns} value={this.state.naziv} onChange={this.handleChangeNaziv} >
                            <option value="">{i18n.t('Type of expense')}</option>
                            {this.state.nazivi && this.state.nazivi.map((naziv, i) => (
                                <option key={i} value={naziv}>{naziv}</option>
                            ))}
                        </select>

                        <select className={style.dropDowns} value={this.state.mesec} onChange={this.handleChangeMesec} >
                            <option value="">{i18n.t('Month')}</option>
                            {this.state.meseci && this.state.meseci.map((mesec, i) => (
                                <option key={i} value={i18n.t(`${mesec}`)}>{i18n.t(`${mesec}`)}</option>
                            ))}
                        </select>

                        <select className={style.dropDowns} value={this.state.selectedOperator} onChange={this.handleChangeGodina} >
                            <option value="">{i18n.t('Years')}</option>
                            {this.state.godine && this.state.godine.map((godina, i) => (
                                <option key={i} value={godina}>{godina}</option>
                            ))}
                        </select>

                        <select className={style.dropDowns} value={this.state.ogranak} onChange={this.handleChangeOgranak} >
                            <option value="">{i18n.t(`Sector`)}</option>
                            {this.state.ogranci && this.state.ogranci.map((sector, i) => (
                                <option key={i} value={sector}>{sector}</option>
                            ))}
                        </select>
                        <link rel="stylesheet" href="./AdministrationOfExtraExpenses.module.css"></link>
                        <button className={style.resetButton} onClick={this.resetMyFilters}>{i18n.t('Reset filters')}</button>

                        <Button
                            onClick={() => this.setState({ exportWindow: !this.state.exportWindow })}
                            variant="fab"
                            color="secondary"
                            aria-label="add"
                            className={style.exportButton}
                        // style={{ marginLeft: 'auto !important' }}

                        >
                            <span className="icon-download headerIcon" />
                        </Button>
                    </div>

                    <div style={{ border: '1px solid #cecece', backgroundColor: '#fff', margin: '0 30px 30px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', minHeight: 'calc(100vh - 245px)', display: 'flex', flexDirection: 'column' }}>
                        <div className={style.tableHeader}>

                            <div className={style.tableHeaderTitle} >{i18n.t("Extra expenses")}</div>

                        </div>

                        <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', margin: '0 16px', padding: '15px 0 8px 0', borderBottom: '1px solid #9b9658', fontSize: '14px', color: '#7d7a7a' }}>
                            <div style={{ width: '80px', marginRight: "20px" }}>{i18n.t("Name")}</div>
                            <div style={{ width: '150px', marginRight: "20px" }}>{i18n.t("Amount")}</div>
                            <div style={{ width: '120px', marginRight: "20px" }}>{i18n.t("Month")}</div>
                            <div style={{ width: '100px', marginRight: "20px" }}>{i18n.t("Year")}</div>
                            <div style={{ width: '200px', marginRight: "20px" }}>{i18n.t("Sector")}</div>

                            <div style={{ width: '177px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Preview")}</div>
                                <div style={{ width: '60px', marginRight: "20px", paddingLeft: '10px' }}>{i18n.t("Edit")}</div>
                                <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Delete")}</div>
                            </div>
                        </div>

                        {this.state.filteredExpenses.length > 0 && this.state.filteredExpenses.slice(this.state.currentPage * this.state.itemsPerPage, (this.state.currentPage + 1) * this.state.itemsPerPage).map((item, i) => (
                            <div key={i} style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', margin: '0 16px', borderBottom: '1px solid #d2d2d2' }}>
                                <div style={{ width: '80px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.naziv}</div>
                                <div style={{ width: '150px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.cena}</div>
                                <div style={{ width: '120px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{i18n.t(`${item.mesec_naziv}`)}</div>
                                <div style={{ width: '100px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.godina_naziv}</div>

                                <div style={{ width: '200px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.sektor_naziv}</div>


                                <div style={{ width: '177px' }}>
                                    <Tooltip title={i18n.t("Preview")}>
                                        <IconButton aria-label="copy"
                                            // color="secondary"
                                            style={{ transform: 'translateX(-15px)' }}
                                            onClick={() => { this.handlePreviewClick(item) }}>
                                            <Icons.Visibility fontSize="large" />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={i18n.t("Edit")}>
                                        <IconButton aria-label="edit"
                                            // color="secondary"
                                            onClick={() => this.openEditExpense(item)}>
                                            <Icons.Edit fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={i18n.t("Delete")}>
                                        <IconButton aria-label="Delete"
                                            // className={classes.cssRoot}
                                            onClick={() => this.onDeleteExpense(item.id)}>
                                            <Icons.DeleteOutline fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        ))}

                        <ReactPaginate
                            pageCount={this.state.pageCount}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            onPageChange={this.handlePageChange}
                            containerClassName={style.paginationContainer}
                            activeClassName={style.active}
                            previousLabel={"<"}
                            nextLabel={">"}
                            forcePage={this.state.currentPage}
                        />

                    </div>
                </div>
                {this.state.exportWindow && <Export
                    export={this.state.exportWindow}
                    onTextChange={({ title }) => { this.setState({ docTitle: title }); }}
                    // handleClose={() => this.setState({ exportWindow: !this.state.exportWindow })}
                    handleClose={this.handleCloseExportWindow}
                    exportDoc={this.exportFunction} />}
                {this.state.addNew && <AddNewExpense add={true} edit={false} handleClose={this.handleAddNewExpense} reload={this.fetchAll} />}
                {this.state.preview && <AddNewExpense add={true} preview={true} edit={false} obj={this.state.previewData} handleClose={this.handleClosePreview} />}
                {this.state.editMode && <AddNewExpense add={true} preview={false} edit={true} obj={this.state.itemForEditing} handleClose={this.handleCloseEdit} reload={this.fetchAll} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}

            </div>

        )
    }
};
